define("discourse/plugins/discourse-doc-categories/discourse/lib/doc-category-sidebar-panel", ["exports", "@glimmer/tracking", "@ember/object", "@ember/template", "discourse/lib/sidebar/base-custom-sidebar-section", "discourse/lib/sidebar/base-custom-sidebar-section-link", "discourse/lib/url", "discourse/lib/utilities", "discourse-common/lib/get-owner", "discourse-common/lib/get-url", "discourse-i18n", "discourse/plugins/discourse-doc-categories/discourse/services/doc-category-sidebar"], function (_exports, _tracking, _object, _template, _baseCustomSidebarSection, _baseCustomSidebarSectionLink, _url, _utilities, _getOwner, _getUrl, _discourseI18n, _docCategorySidebar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const sidebarPanelClassBuilder = BaseCustomSidebarPanel => class DocCategorySidebarPanel extends BaseCustomSidebarPanel {
    key = (() => _docCategorySidebar.SIDEBAR_DOCS_PANEL)();
    hidden = true;
    displayHeader = true;
    expandActiveSection = true;
    scrollActiveLinkIntoView = true;
    get docCategorySidebar() {
      return (0, _getOwner.getOwnerWithFallback)(this).lookup("service:doc-category-sidebar");
    }
    get sections() {
      const router = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:router");
      return this.docCategorySidebar.sectionsConfig.map(config => {
        return prepareDocsSection({
          config,
          router
        });
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "sections", [_tracking.cached]))();
    get filterable() {
      return !this.docCategorySidebar.loading;
    }
    filterNoResultsDescription(filter) {
      const active = this.docCategorySidebar.activeCategory;
      let categoryFilter = "";
      if (this.docCategorySidebar.activeCategory) {
        categoryFilter = " " + (this.#assembleCategoryFilter("", active, 1) ?? `category:${active.id}`);
      }
      const params = {
        filter: (0, _utilities.escapeExpression)(filter),
        content_search_url: (0, _getUrl.default)(`/search?q=${encodeURIComponent(filter + categoryFilter)}`),
        site_search_url: (0, _getUrl.default)(`/search?q=${encodeURIComponent(filter)}`)
      };
      return (0, _template.htmlSafe)(_discourseI18n.default.t("doc_categories.sidebar.filter.no_results.description", params));
    }
    #assembleCategoryFilter(filter, category, level) {
      if (!category) {
        return filter;
      }
      if (level > 2) {
        return null;
      }
      if (category.parentCategory) {
        return this.#assembleCategoryFilter(":" + category.slug, category.parentCategory, level + 1);
      } else {
        return "#" + category.slug + filter;
      }
    }
  };
  var _default = _exports.default = sidebarPanelClassBuilder;
  function prepareDocsSection(_ref) {
    let {
      config,
      router
    } = _ref;
    return class extends _baseCustomSidebarSection.default {
      #config = (() => config)();
      get sectionLinks() {
        return this.#config.links;
      }
      get name() {
        return this.text ? `${_docCategorySidebar.SIDEBAR_DOCS_PANEL}__${(0, _utilities.unicodeSlugify)(this.text)}` : `${_docCategorySidebar.SIDEBAR_DOCS_PANEL}::root`;
      }
      get title() {
        return this.#config.text;
      }
      get text() {
        return this.#config.text;
      }
      get links() {
        return this.sectionLinks.map(sectionLinkData => new DocCategorySidebarSectionLink({
          data: sectionLinkData,
          panelName: this.name,
          router
        }));
      }
      get displaySection() {
        return true;
      }
      get hideSectionHeader() {
        return !this.text;
      }
      get collapsedByDefault() {
        return false;
      }
    };
  }
  class DocCategorySidebarSectionLink extends _baseCustomSidebarSectionLink.default {
    #data;
    #panelName;
    #router;
    constructor(_ref2) {
      let {
        data,
        panelName,
        router
      } = _ref2;
      super(...arguments);
      this.#data = data;
      this.#panelName = panelName;
      this.#router = router;
    }
    get currentWhen() {
      if (_url.default.isInternal(this.href) && (0, _getUrl.samePrefix)(this.href)) {
        const topicRouteInfo = this.#router.recognize(this.href.replace((0, _getUrl.getAbsoluteURL)("/"), "/"), "").find(route => route.name === "topic");
        const currentTopicRouteInfo = this.#router.currentRoute.find(route => route.name === "topic");
        return currentTopicRouteInfo && currentTopicRouteInfo?.params?.id === topicRouteInfo?.params?.id;
      }
      return false;
    }
    get name() {
      return `${this.#panelName}___${(0, _utilities.unicodeSlugify)(this.#data.text)}`;
    }
    get classNames() {
      const list = ["docs-sidebar-nav-link"];
      return list.join(" ");
    }
    get href() {
      return this.#data.href;
    }
    get text() {
      return this.#data.text;
    }
    get title() {
      return this.#data.text;
    }
    get keywords() {
      return {
        navigation: this.#data.text.toLowerCase().split(/\s+/g)
      };
    }
    static #_ = (() => dt7948.n(this.prototype, "keywords", [(0, _object.computed)("data.text")]))();
    get prefixType() {
      return "icon";
    }
    get prefixValue() {
      return "far-file";
    }
  }
});