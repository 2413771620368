define("discourse/plugins/discourse-doc-categories/discourse/services/doc-category-sidebar", ["exports", "@glimmer/tracking", "@ember/service", "discourse/lib/sidebar/panels", "discourse-common/lib/object", "discourse-common/utils/decorators"], function (_exports, _tracking, _service, _panels, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SIDEBAR_DOCS_PANEL = void 0;
  const SIDEBAR_DOCS_PANEL = _exports.SIDEBAR_DOCS_PANEL = "discourse-docs-sidebar";
  class DocCategorySidebarService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "messageBus", [_service.inject]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "sidebarState", [_service.inject]))();
    #sidebarState = (() => (dt7948.i(this, "sidebarState"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "store", [_service.inject]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "_indexCategoryId", [_tracking.tracked], function () {
      return null;
    }))();
    #_indexCategoryId = (() => (dt7948.i(this, "_indexCategoryId"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "_indexConfig", [_tracking.tracked], function () {
      return null;
    }))();
    #_indexConfig = (() => (dt7948.i(this, "_indexConfig"), void 0))();
    constructor() {
      super(...arguments);
      this.router.on("routeDidChange", this, this.currentRouteChanged);
      this.messageBus.subscribe("/categories", this.maybeUpdateIndexContent);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.router.off("routeDidChange", this, this.currentRouteChanged);
      this.messageBus.unsubscribe("/categories", this.maybeUpdateIndexContent);
    }
    get activeCategory() {
      return this.router.currentRoute?.attributes?.category || this.router.currentRoute?.parent?.attributes?.category;
    }
    get isEnabled() {
      return !!this._activeIndex;
    }
    get isVisible() {
      return this.sidebarState.isCurrentPanel(SIDEBAR_DOCS_PANEL);
    }
    get loading() {
      return this._loading;
    }
    get sectionsConfig() {
      return this._indexConfig || [];
    }
    hideDocsSidebar() {
      if (!this.isVisible) {
        return;
      }
      this.sidebarState.setPanel(_panels.MAIN_PANEL);
    }
    showDocsSidebar() {
      this.sidebarState.setPanel(SIDEBAR_DOCS_PANEL);
      this.sidebarState.setSeparatedMode();
      this.sidebarState.hideSwitchPanelButtons();
    }
    disableDocsSidebar() {
      this.hideDocsSidebar();
      this._indexCategoryId = null;
      this._indexConfig = null;
    }
    maybeUpdateIndexContent(data) {
      // if the docs sidebar is not displayed, tries to display it
      if (!this.isVisible) {
        this.#maybeForceDocsSidebar();
        return;
      }

      // if the docs sidebar is displayed, checks if the index needs to be updated for the current category
      const updatedCategory = data.categories?.find(c => c.id === this._indexCategoryId);
      if (updatedCategory) {
        this.#setSidebarContent(this._indexCategoryId, updatedCategory.doc_category_index);
      }

      // if the category no longer exists hides the docs sidebar
      if (data.deleted_categories?.find(id => id === this._indexCategoryId)) {
        this.disableDocsSidebar();
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "maybeUpdateIndexContent", [_decorators.bind]))();
    currentRouteChanged(transition) {
      if (transition.isAborted) {
        return;
      }
      this.#maybeForceDocsSidebar();
    }
    static #_9 = (() => dt7948.n(this.prototype, "currentRouteChanged", [_decorators.bind]))();
    #findIndexForActiveCategory() {
      let category = this.activeCategory;
      while (category != null) {
        const categoryId = category.id;
        const indexConfig = category.doc_category_index;
        if (indexConfig) {
          return {
            categoryId,
            indexConfig
          };
        }
        category = category.parentCategory;
      }
      return {};
    }
    #maybeForceDocsSidebar() {
      const {
        categoryId,
        indexConfig: newIndexConfig
      } = this.#findIndexForActiveCategory();
      if (!newIndexConfig) {
        this.disableDocsSidebar();
        return;
      }
      if (this._indexCategoryId !== categoryId || !(0, _object.deepEqual)(this._indexConfig, newIndexConfig)) {
        this.#setSidebarContent(categoryId, newIndexConfig);
      }
    }
    #setSidebarContent(categoryId, indexConfig) {
      if (!indexConfig) {
        this.disableDocsSidebar();
        return;
      }
      this._indexCategoryId = categoryId;
      this._indexConfig = indexConfig;
      this.showDocsSidebar();
    }
  }
  _exports.default = DocCategorySidebarService;
});