define("discourse/plugins/discourse-doc-categories/discourse/docs-legacy-compatibility-route-map", ["exports", "discourse-common/lib/get-owner"], function (_exports, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    const site = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:site");
    const docsPath = site.docs_legacy_path;
    if (!docsPath) {
      return;
    }
    this.route("doc-legacy-docs", {
      path: "/" + docsPath
    });
    this.route("doc-legacy-kb-xplr", {
      path: "/knowledge-explorer"
    });
  }
});