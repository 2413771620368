define("discourse/plugins/discourse-doc-categories/discourse/initializers/doc-categories", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-doc-categories/discourse/components/doc-category-settings", "discourse/plugins/discourse-doc-categories/discourse/lib/doc-category-sidebar-panel"], function (_exports, _pluginApi, _docCategorySettings, _docCategorySidebarPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "doc-categories",
    initialize(container) {
      container.lookup("service:doc-category-sidebar");
      (0, _pluginApi.withPluginApi)("1.34.0", api => {
        api.renderInOutlet("category-custom-settings", _docCategorySettings.default);
        api.addSidebarPanel(_docCategorySidebarPanel.default);
      });
    }
  };
});