define("discourse/plugins/discourse-doc-categories/discourse/routes/doc-legacy-docs", ["exports", "@ember/service", "@ember/utils", "discourse/lib/url", "discourse/routes/discourse", "discourse-common/lib/get-url"], function (_exports, _service, _utils, _url, _discourse, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DocLegacyDocsRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    beforeModel(transition) {
      const queryParams = new URLSearchParams(transition.intent.url.split("?")?.[1]);

      // if there is a `topic` parameter provided. try to redirect to the corresponding topic
      const topicId = queryParams.get("topic");

      // remove the topic param from the list
      queryParams.delete("topic");
      if (topicId) {
        this.redirectToTopic(transition, topicId, queryParams);
        return;
      }

      // if a topic was not provided try to redirect to the default homepage, if one was set
      this.redirectToHomepage(transition, queryParams);
    }
    redirectToTopic(transition, topicId, queryParams) {
      if (Number.isInteger(parseInt(topicId, 10))) {
        let url = `/t/${topicId}${this.prepareQueryParams(queryParams)}`;
        transition.abort();
        _url.default.routeTo(url);
      } else {
        this.router.replaceWith("/404");
      }
    }
    redirectToHomepage(transition, queryParams) {
      // if a topic was not provided try to redirect to the default homepage, if one was set
      if ((0, _utils.isPresent)(this.siteSettings.doc_categories_homepage)) {
        transition.abort();
        const targetURL = `${this.siteSettings.doc_categories_homepage}${this.prepareQueryParams(queryParams)}`;
        if (_url.default.isInternal(targetURL) && (0, _getUrl.samePrefix)(targetURL)) {
          _url.default.routeTo(targetURL);
        } else {
          _url.default.redirectTo(targetURL);
        }
        return;
      }

      // fallback to 404
      this.router.replaceWith("/404");
    }
    prepareQueryParams(queryParams) {
      const urlParams = queryParams.toString();
      if ((0, _utils.isPresent)(urlParams)) {
        return `?${urlParams}`;
      }
      return "";
    }
  }
  _exports.default = DocLegacyDocsRoute;
});